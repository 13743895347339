"use strict";

function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.shallowCompare = exports.seal = exports.partitionObject = exports.mlynify = exports.getMlynProps = exports.compareArrays = void 0;
var _react = _interopRequireWildcard(require("react"));
var _mlyn = require("mlyn");
var _excluded = ["children"],
  _excluded2 = ["children"];
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }
function _iterableToArrayLimit(arr, i) { var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"]; if (null != _i) { var _s, _e, _x, _r, _arr = [], _n = !0, _d = !1; try { if (_x = (_i = _i.call(arr)).next, 0 === i) { if (Object(_i) !== _i) return; _n = !1; } else for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = !0) { ; } } catch (err) { _d = !0, _e = err; } finally { try { if (!_n && null != _i["return"] && (_r = _i["return"](), Object(_r) !== _r)) return; } finally { if (_d) throw _e; } } return _arr; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }
function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }
var partitionObject = function partitionObject(entries) {
  return Object.keys(entries).reduce(function (result, key) {
    var lastIndex = key.length - 1;
    if (key.charAt(lastIndex) === "$") {
      result[1][key.substring(0, lastIndex)] = entries[key];
    } else {
      result[0][key] = entries[key];
    }
    return result;
  }, [{}, {}]);
};
exports.partitionObject = partitionObject;
var getMlynProps = function getMlynProps(entries) {
  return Object.keys(entries).reduce(function (result, key) {
    var lastIndex = key.length - 1;
    if (key.charAt(lastIndex) === "$") {
      result[key.substr(0, lastIndex)] = entries[key];
    }
    return result;
  }, {});
};
exports.getMlynProps = getMlynProps;
var seal = function seal(Component) {
  return _react["default"].memo(Component, function () {
    return true;
  });
};
exports.seal = seal;
var unitialized = {};
var emptyArray = [];
var emptyObject = [];
var isNotEmpty = function isNotEmpty(o) {
  for (var i in o) {
    return true;
  }
  return false;
};
var mlynify = function mlynify(tag) {
  return (0, _react.memo)(function (props) {
    var _useMemo = (0, _react.useMemo)(function () {
        var _partitionObject = partitionObject(props),
          _partitionObject2 = _slicedToArray(_partitionObject, 2),
          initialState = _partitionObject2[0],
          mlynProps = _partitionObject2[1];
        Object.keys(mlynProps).forEach(function (key) {
          initialState[key] = mlynProps[key]();
        });
        return initialState;
      }, emptyArray),
      initialChild = _useMemo.children,
      initialState = _objectWithoutProperties(_useMemo, _excluded);
    var children = props.children,
      rest = _objectWithoutProperties(props, _excluded2);
    var _useState = (0, _react.useState)(initialState),
      _useState2 = _slicedToArray(_useState, 2),
      state = _useState2[0],
      setState = _useState2[1];
    var _useState3 = (0, _react.useState)(initialChild),
      _useState4 = _slicedToArray(_useState3, 2),
      childState = _useState4[0],
      setChild = _useState4[1];
    (0, _react.useEffect)(function () {
      var _partitionObject3 = partitionObject(rest),
        _partitionObject4 = _slicedToArray(_partitionObject3, 2),
        plainProps = _partitionObject4[0],
        mlynProps = _partitionObject4[1];
      var scope = (0, _mlyn.runInReactiveScope)(function () {
        var newValues = {};
        var changed = false;
        Object.keys(mlynProps).forEach(function (key) {
          var newValue = mlynProps[key]();
          if (newValues[key] !== newValue) {
            changed = true;
          }
          newValues[key] = newValue;
        });
        if (changed) {
          setState(_objectSpread(_objectSpread({}, plainProps), newValues));
        }
      });
      var childScope = (0, _mlyn.runInReactiveScope)(function () {
        if (typeof children === "function") {
          // @ts-ignore
          var newValue = children();
          setChild(newValue);
        }
      });
      return function () {
        if (scope) {
          scope.destroy();
        }
        if (childScope) {
          childScope.destroy();
        }
      };
    }, emptyArray);
    return _react["default"].createElement(tag, Object.assign({}, state), childState);
  });
};
exports.mlynify = mlynify;
var shallowCompare = function shallowCompare(a, b) {
  if (a === undefined || b === undefined) {
    return a === b;
  }
  for (var key in a) {
    if (a[key] !== b[key]) {
      return false;
    }
  }
  return true;
};
exports.shallowCompare = shallowCompare;
var compareArrays = function compareArrays(first, second) {
  if (!first || !second) {
    return false;
  }
  if (first.length !== second.length) {
    return false;
  }
  for (var i = 0; i < first.length; i++) {
    if (first[i] !== second[i]) {
      return false;
    }
  }
  return true;
};
exports.compareArrays = compareArrays;