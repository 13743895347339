"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useHandler = useHandler;

var _react = require("react");

/**
 * This hook makes a proxy for a function.
 * It guarantees to return the same instance across multiple renders. It calls nothing if the actual handler is nullish.
 * @param f Some being recreated function to wrap.
 * @returns function.
 */
function useHandler(f) {
  const ref = (0, _react.useRef)(f);
  (0, _react.useLayoutEffect)(() => {
    ref.current = f;
  });
  const proxy = (0, _react.useRef)(function (...args) {
    return ref.current == null ? undefined : ref.current.apply(this, args);
  });
  return proxy.current;
}