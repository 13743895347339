"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useSubjectValue = exports.useSubjectInputBinding = exports.useSubject = exports.useProjectSubject = exports.useProjectArray = exports.useObervableValue = exports.useMlynEffect = exports.useMemoize = exports.useForceUpdate = exports.useDestroyable = exports.useCompute = void 0;
var _react = require("react");
var _mlyn = require("mlyn");
function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }
function _iterableToArrayLimit(arr, i) { var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"]; if (null != _i) { var _s, _e, _x, _r, _arr = [], _n = !0, _d = !1; try { if (_x = (_i = _i.call(arr)).next, 0 === i) { if (Object(_i) !== _i) return; _n = !1; } else for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = !0) { ; } } catch (err) { _d = !0, _e = err; } finally { try { if (!_n && null != _i["return"] && (_r = _i["return"](), Object(_r) !== _r)) return; } finally { if (_d) throw _e; } } return _arr; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
var useSubject = function useSubject(initialValue) {
  return (0, _react.useMemo)(function () {
    return (0, _mlyn.createSubject)(initialValue);
  }, []);
};
exports.useSubject = useSubject;
var useDestroyable = function useDestroyable(callback) {
  var _useMemo = (0, _react.useMemo)(callback, []),
    _useMemo2 = _slicedToArray(_useMemo, 2),
    observable$ = _useMemo2[0],
    destroyable = _useMemo2[1];
  (0, _react.useEffect)(function () {
    return function () {
      return destroyable.destroy();
    };
  }, []);
  return observable$;
};
exports.useDestroyable = useDestroyable;
var useMlynEffect = function useMlynEffect(callback) {
  (0, _react.useEffect)(function () {
    var scope = (0, _mlyn.runInReactiveScope)(callback);
    // @ts-ignore
    return function () {
      return scope.destroy();
    };
  }, []); // no dependencies, run only once
};

/**
 * returns one way bindable function
 * @param cb
 * @returns
 */
exports.useMlynEffect = useMlynEffect;
var useMemoize = function useMemoize(cb) {
  var subject$ = useSubject(cb());
  useMlynEffect(function () {
    var newValue = cb();
    subject$(newValue);
  });
  return function () {
    return subject$();
  };
};

/**
 * returns a 2-way bindable subject
 * @param cb
 * @returns
 */
exports.useMemoize = useMemoize;
var useProjectSubject = function useProjectSubject(projection) {
  var _useMemo3 = (0, _react.useMemo)(function () {
      return (0, _mlyn.projectSubject)(projection);
    }, []),
    _useMemo4 = _slicedToArray(_useMemo3, 2),
    result = _useMemo4[0],
    scope = _useMemo4[1];
  (0, _react.useEffect)(function () {
    return scope;
  }, []);
  return result;
};

/**
 * returns an array projection, every entry is 2-way bindable
 * @param cb
 * @returns
 */
exports.useProjectSubject = useProjectSubject;
var useProjectArray = function useProjectArray(array$, projection, getKey) {
  var _useMemo5 = (0, _react.useMemo)(function () {
      return (0, _mlyn.projectArray)(array$, projection, getKey);
    }, []),
    _useMemo6 = _slicedToArray(_useMemo5, 2),
    result = _useMemo6[0],
    scope = _useMemo6[1];
  (0, _react.useEffect)(function () {
    return function () {
      return scope.destroy();
    };
  }, []);
  return result;
};

/**
 * causes component re-rendering on computed value change
 * @param callback
 * @returns
 */
exports.useProjectArray = useProjectArray;
var useCompute = function useCompute(callback) {
  var _useState = (0, _react.useState)(callback()),
    _useState2 = _slicedToArray(_useState, 2),
    computed = _useState2[0],
    setComputed = _useState2[1];
  useMlynEffect(function () {
    setComputed(callback());
  });
  return computed;
};

/**
 * causes component re-rendering on subject value change
 * @param callback
 * @returns
 */
exports.useCompute = useCompute;
var useSubjectValue = function useSubjectValue(subject) {
  return useCompute(function () {
    return subject();
  });
};
exports.useSubjectValue = useSubjectValue;
var useForceUpdate = function useForceUpdate() {
  var _useState3 = (0, _react.useState)(0),
    _useState4 = _slicedToArray(_useState3, 2),
    forceUpdate = _useState4[1];
  return function () {
    return forceUpdate(function (v) {
      return v + 1;
    });
  };
};
exports.useForceUpdate = useForceUpdate;
var unitialized = {};

/**
 * causes re-render
 * @param observable
 * @returns
 */
var useObervableValue = function useObervableValue(observable) {
  var _useState5 = (0, _react.useState)(observable()),
    _useState6 = _slicedToArray(_useState5, 2),
    state = _useState6[0],
    setState = _useState6[1];
  (0, _react.useEffect)(function () {
    var scope = (0, _mlyn.runInReactiveScope)(function () {
      setState(observable());
    });
    return function () {
      return scope.destroy();
    };
  }, []);
  return state;
};

/**
 * causes component re-rendering on subject value change
 * @param callback
 * @returns
 */
exports.useObervableValue = useObervableValue;
var useSubjectInputBinding = function useSubjectInputBinding(subject) {
  var value = useSubjectValue(subject);
  return {
    value: value,
    onChange: function onChange(e) {
      return subject(e.target.value);
    }
  };
};
exports.useSubjectInputBinding = useSubjectInputBinding;